import { required, minLength, maxLength } from "vuelidate/lib/validators"
import { buildPricesValidator } from "./../helpers"
import { NAME_MAX_LENGTH } from "./constants"

export default priceKeys => ({
  name: {
    required,
    maxLength: maxLength(NAME_MAX_LENGTH)
  },
  time_slot_id: { required },
  price_rows: {
    required,
    minLength: minLength(1),
    $each: {
      car_class_id: { required },
      prices: buildPricesValidator(priceKeys)
    }
  }
})
